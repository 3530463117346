import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material';
import PermanentDrawerLeft from '../Layout.tsx';
import './ExpensesPage.css'; // Import the CSS file for styling

const reviewExpensesReports = [
  {
    name: 'Report 1',
    amount: '$300',
    status: 'Submitted',
    createdBy: 'User C',
    creationTime: '2023-10-03 12:00 PM',
  },
  {
    name: 'Report 2',
    amount: '$400',
    status: 'Paid',
    createdBy: 'User D',
    creationTime: '2023-10-04 01:00 PM',
  },
  // Add more sample data as needed
];

const ReviewExpensesPage = () => {
  return (
    <PermanentDrawerLeft>
      <div style={{ padding: '20px', flexShrink: 0 }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: '20px',
            height: '70px',
          }}
        >
          <h1 style={{ margin: 0 }}>Review Expenses Reports</h1>
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Report Name</TableCell>
                <TableCell>Report Amount</TableCell>
                <TableCell>Report Status</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Creation Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviewExpensesReports.map((report, index) => (
                <TableRow key={index}>
                  <TableCell>{report.name}</TableCell>
                  <TableCell>{report.amount}</TableCell>
                  <TableCell>{report.status}</TableCell>
                  <TableCell>{report.createdBy}</TableCell>
                  <TableCell>{report.creationTime}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </PermanentDrawerLeft>
  );
};

export default ReviewExpensesPage;
