import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Drawer,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Import icon for My Profile

const drawerWidth = 240;

const PermanentDrawerLeft = ({ children }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('sessionToken');
    localStorage.removeItem('user');
    navigate('/login');
  };
  const userData = localStorage.getItem('user');
  let displayName = '';

  if (userData) {
    const user = JSON.parse(userData);
    displayName = user.user.display;
  }

  return (
    <div style={{ display: 'flex', flexGrow: 1, flexShrink: 0, width: '100%' }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Divider />
        <List>
          <ListItem key="home" disablePadding>
            <ListItemButton component={Link} to="/">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home Page" />
            </ListItemButton>
          </ListItem>
          <ListItem key="my_expenses" disablePadding>
            <ListItemButton component={Link} to="/expenses/my">
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary="My Expenses" />
            </ListItemButton>
          </ListItem>
          <ListItem key="review_expenses" disablePadding>
            <ListItemButton component={Link} to="/expenses/review">
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText primary="Review Expenses" />
            </ListItemButton>
          </ListItem>
          <ListItem key="profile" disablePadding>
            <ListItemButton component={Link} to="/profile">
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem key="logout" disablePadding>
            <ListItemButton onClick={handleLogout}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
        <div style={{ marginTop: 'auto', padding: '16px' }}>
          {displayName && (
            <ListItem>
              <ListItemText primary={`Logged in as: ${displayName}`} />
            </ListItem>
          )}
        </div>
      </Drawer>

      <div
        style={{
          padding: '16px',
          alignSelf: 'flex-start',
          left: drawerWidth,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '900px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default PermanentDrawerLeft;
