import React, { useEffect, useState } from 'react';
import {
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Table,
  TableHead,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import AddExpenseModal from './AddExpenseModal';
import { ExpenseItem } from './models';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Divider from '@mui/material/Divider';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import Chip from '@mui/material/Chip';
import { BASE_URL } from '../endpoints/constants.jsx';
import { getExpenseReport } from '../endpoints/expenseApi';

const ExpenseReportSubmission = () => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [expenses, setExpenses] = useState<ExpenseItem[]>([]);
  const [modalOpen, setModalOpen] = useState(false); // State to control the AddExpenseModal visibility
  const [loading, setLoading] = useState(true); // State to track loading
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // State to manage menu open/close

  const [expenseReport, setExpenseReport] = useState({
    title: '',
    description: '',
    status: '',
    id: '',
    start_date: '',
    end_date: '',
    username: '',
  });

  const navigate = useNavigate();
  const reportId = new URLSearchParams(window.location.search).get('report_id');

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // finish this function
    setExpenseReport({ ...expenseReport, [name]: value });
  };

  const getCategoryColor = (category?: string | null) => {
    category = category || '';
    category = category.toLowerCase();
    switch (category) {
      case 'food':
        return 'primary';
      case 'transportation':
        return 'secondary';
      case 'accommodation':
        return 'default';
      case 'entertainment':
        return 'success';
      case 'other':
        return 'warning';
      default:
        return 'default';
    }
  };

  const getReportData = async () => {
    setLoading(true); // Set loading to true before fetching data

    try {
      const data = await getExpenseReport(reportId);

      setExpenseReport(data);
      // sort data.expense_items by created_at desc
      data.expense_items.sort((a, b) => {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
      });
      setExpenses(data.expense_items);
      setLoading(false); // Set loading to false after fetching data
    } catch (error) {
      console.error('Error fetching report data:', error);
      setLoading(false); // Set loading to false after fetching data
    } finally {
      setLoading(false); // Set loading to false after fetching data
    }
  };

  useEffect(() => {
    getReportData();
  }, []);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false); // Close the AddExpenseModal
  };

  const handleModalSave = (expense: ExpenseItem) => {
    setExpenses([...expenses, expense]);
    setModalOpen(false); // Close the AddExpenseModal
  };

  const handleAddExpenseClick = () => {
    setModalOpen(true); // Open the AddExpenseModal
  };

  const handleSubmit = async () => {
    const response = await fetch(
      `${BASE_URL}/expenses/expense-reports/${reportId}/`,
      {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'x-session-token': localStorage.getItem('sessionToken'),
        },
        credentials: 'include',
        body: JSON.stringify({
          status: 'SUBMITTED',
        }),
      }
    );

    if (response.ok) {
      navigate('/expenses/my');
    } else {
      console.error('Failed to submit report');
    }
  };
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleArchiveReport = async () => {};
  const handleConfirmExit = () => {
    setOpen(false);
    navigate('/expenses/my');
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatFullDateTime = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  return (
    <div
      style={{
        width: '80%',
        margin: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignSelf: 'center',
      }}
    >
      {loading && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!loading && (
        <div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: 8,
              }}
            >
              <div style={{ display: 'flex', gap: 5 }}>
                <Chip
                  label={expenseReport.status}
                  variant="outlined"
                  size="small"
                />
              </div>
              <div
                style={{ display: 'flex', justifyContent: 'flex-end', gap: 5 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
                <IconButton onClick={handleMenuOpen}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handleArchiveReport}>
                    Archive Report
                  </MenuItem>
                </Menu>
                <IconButton onClick={handleConfirmExit}>
                  <ClearOutlinedIcon />
                </IconButton>
              </div>
            </div>
            <Divider orientation="horizontal" flexItem />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <h1>{expenseReport.title}</h1>
            <h3>
              {expenseReport.username
                ? `申请人: ${expenseReport.username}`
                : ''}
            </h3>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddExpenseClick}
            >
              Add Expense
            </Button>
          </div>

          <div style={{ marginTop: '20px', width: '100%' }}>
            <Box
              component="form"
              sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
            >
              <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Expense Date"
                    value={
                      expenseReport.start_date
                        ? dayjs(expenseReport.start_date)
                        : null
                    }
                    onChange={(newValue) =>
                      handleChange({
                        target: { name: 'start_date', value: newValue },
                      })
                    }
                    slotProps={{
                      textField: {
                        helperText: 'MM/DD/YYYY',
                        size: 'small',
                      },
                    }}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={
                      expenseReport.end_date
                        ? dayjs(expenseReport.end_date)
                        : null
                    }
                    onChange={(newValue) =>
                      handleChange({
                        target: { name: 'end_date', value: newValue },
                      })
                    }
                    slotProps={{
                      textField: {
                        helperText: 'MM/DD/YYYY',
                        size: 'small',
                      },
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <TextField
                label="Description"
                name="description"
                value={expenseReport.description}
                onChange={handleChange}
                size="small"
                multiline
                required
                minRows={4}
              />
            </Box>
          </div>
          {expenses.length > 0 && (
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      Description
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      Amount
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      Txn Date
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      Category
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      Merchant
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      Service Date
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      Reference Number
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', backgroundColor: '#f5f5f5' }}
                    >
                      Currency
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {expenses.map((expense) => (
                    <TableRow key={expense.id}>
                      <TableCell>{expense.description}</TableCell>
                      <TableCell>{expense.amount_paid}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip
                          title={
                            expense.txn_date &&
                            formatFullDateTime(expense.txn_date)
                          }
                        >
                          <span>
                            {expense.txn_date && formatDate(expense.txn_date)}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Chip
                          label={expense.category}
                          color={getCategoryColor(expense.category)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>{expense.merchant_name}</TableCell>
                      <TableCell style={{ whiteSpace: 'nowrap' }}>
                        <Tooltip
                          title={
                            expense.service_date &&
                            formatFullDateTime(expense.service_date)
                          }
                        >
                          <span>
                            {expense.service_date &&
                              formatDate(expense.service_date)}
                          </span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{expense.reference_number}</TableCell>
                      <TableCell>{expense.currency}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{'Confirm Exit'}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to exit? Any unsaved changes will be lost.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmExit} color="secondary" autoFocus>
                Exit
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <MuiAlert
              onClose={handleSnackbarClose}
              severity="success"
              sx={{ width: '100%' }}
            >
              Image uploaded successfully!
            </MuiAlert>
          </Snackbar>
          <Dialog
            fullScreen
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="add-expense-modal-title"
          >
            <AddExpenseModal
              open={modalOpen}
              onClose={handleModalClose}
              onSave={handleModalSave}
              reportId={reportId}
            />
          </Dialog>
        </div>
      )}
    </div>
  );
};

export default ExpenseReportSubmission;
