import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  Typography,
  Button,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Table,
  TableContainer,
  Paper,
  Link,
  Chip,
  Tooltip,
} from '@mui/material';
import PermanentDrawerLeft from '../Layout';
import { useNavigate } from 'react-router-dom';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { ExpenseReport } from './models';
import {
  createExpenseReport,
  listExpenseReports,
} from '../endpoints/expenseApi';

const MyExpensesPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [reportName, setReportName] = useState('');
  const [businessPurpose, setBusinessPurpose] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [reportNameError, setReportNameError] = useState(false);

  const [myExpensesReports, setMyExpensesReports] = useState<ExpenseReport[]>(
    []
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const data = await listExpenseReports();
        setMyExpensesReports(data);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };

    fetchReports();
  }, []);

  const handleStartReport = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (reportName.trim() === '') {
      setReportNameError(true);
      return;
    }
    const reportData = {
      title: reportName,
      description: businessPurpose,
      status: 'DRAFT',
      start_date: startDate,
      end_date: endDate,
    };
    try {
      const response = await createExpenseReport(reportData);
      if (response) {
        const reportId = response.id;
        navigate(`/expenses/submit?report_id=${reportId}`);
      }
    } catch (error) {
      console.error('Error creating report:', error);
    }
    setOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error.message}</Typography>;
  }

  const handleRowClick = (id: string) => {
    navigate(`/expenses/submit?report_id=${id}`);
  };

  const handleReportNameChange = (e) => {
    setReportName(e.target.value);
    if (e.target.value.trim() !== '') {
      setReportNameError(false);
    }
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatFullDateTime = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      timeZoneName: 'short',
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  return (
    <PermanentDrawerLeft>
      <div style={{ padding: '20px', flexShrink: 0 }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
            paddingBottom: '20px',
            height: '70px',
          }}
        >
          <h1 style={{ margin: 0 }}>My Expenses Reports</h1>
          <Button
            onClick={handleStartReport}
            variant="contained"
            sx={{
              marginLeft: 'auto', // Push the button to the right
              padding: '10px 20px',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              height: '50%',
            }}
          >
            Start Report
          </Button>
        </div>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>
            New Report
            <Button
              onClick={handleClose}
              style={{ position: 'absolute', right: 8, top: 8 }}
            >
              X
            </Button>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Report Name"
              type="text"
              fullWidth
              required
              placeholder="e.g., Trip to New York"
              value={reportName}
              onChange={handleReportNameChange}
              error={reportNameError}
              helperText={reportNameError ? 'Report Name is required' : ''}
              sx={{ marginBottom: '16px' }}
            />
            <TextField
              margin="dense"
              label="Business Purpose"
              type="text"
              fullWidth
              multiline
              rows={4}
              placeholder="Describe the purpose of the report"
              inputProps={{ maxLength: 500 }}
              value={businessPurpose}
              onChange={(e) => setBusinessPurpose(e.target.value)}
              sx={{ marginBottom: '16px' }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '16px',
              }}
            >
              <TextField
                margin="dense"
                label="Start Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                sx={{ marginRight: '8px' }}
              />
              <TextField
                margin="dense"
                label="End Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                sx={{ marginLeft: '8px' }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button onClick={handleSave} variant="contained" color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <TableContainer component={Paper}>
          <Table sx={{ width: '100%' }}>
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Created At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {myExpensesReports.map((report) => (
                <TableRow key={report.id}>
                  <TableCell>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => handleRowClick(report.id)}
                    >
                      {report.title}
                    </Link>
                  </TableCell>
                  <TableCell>{report.description}</TableCell>
                  <TableCell>
                    <Chip
                      label={report.status}
                      variant="outlined"
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={formatFullDateTime(report.created_at)}
                      placement="top"
                    >
                      <span>{formatDate(report.created_at)}</span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </PermanentDrawerLeft>
  );
};

export default MyExpensesPage;
