import { ExpenseReport } from '../expenses/models';
import { BASE_URL } from './constants';

export const listExpenseReports = async (): Promise<ExpenseReport[]> => {
  const response = await fetch(
    `${BASE_URL}/expenses/expense-reports/?format=json`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-session-token': localStorage.getItem('sessionToken'),
      },
      credentials: 'include', // This line ensures cookies are included
    }
  );

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data: ExpenseReport[] = await response.json();
  return data;
};

export const createExpenseReport = async (reportData) => {
  const response = await fetch(`${BASE_URL}/expenses/expense-reports/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-session-token': localStorage.getItem('sessionToken'),
    },
    credentials: 'include',
    body: JSON.stringify(reportData),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data;
};

export const getExpenseReport = async (reportId: string) => {
  const response = await fetch(
    `${BASE_URL}/expenses/expense-reports/` + reportId,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'x-session-token': localStorage.getItem('sessionToken'),
      },
      credentials: 'include',
    }
  );

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  const data = await response.json();
  return data;
};
