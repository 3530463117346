import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ExpenseItem } from './models';
import { BASE_URL } from '../endpoints/constants.jsx';

const { Dragger } = Upload;

const AddExpenseModal = ({ open, onClose, onSave, reportId }) => {
  const [claimReimbursement, setClaimReimbursement] = useState(false);
  const [expenseItemData, setExpenseItemData] = useState<ExpenseItem>({
    id: '',
    report: null,
    amount_paid: 0,
    amount_requested: 0,
    currency: 'CNY',
    txn_date: null,
    category: null,
    merchant_name: null,
    service_date: null,
    description: null,
    reference_number: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setExpenseItemData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (claimReimbursement) {
      setExpenseItemData((prevData) => ({
        ...prevData,
        amount_requested: prevData.amount_paid,
      }));
    }
  };

  const handleSaveAndClose = async () => {
    var formattedExpenseItemData = {
      ...expenseItemData,
      txn_date: expenseItemData.txn_date
        ? dayjs(expenseItemData.txn_date).format('YYYY-MM-DD')
        : null,
    };

    // remove id
    delete formattedExpenseItemData.id;
    formattedExpenseItemData.report = reportId;

    try {
      const response = await fetch(`${BASE_URL}/expenses/expense-items/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-session-token': localStorage.getItem('sessionToken'),
        },
        body: JSON.stringify(formattedExpenseItemData),
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      onSave(data); // Pass the response data to the parent component
      onClose();
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const currencies = [
    { value: 'CNY', label: '¥ 人民币' },
    { value: 'USD', label: '$ 美元' },
    { value: 'HKD', label: 'HK$ 港币' },
  ];

  const categories = [
    { value: 'food', label: 'Food' },
    { value: 'transportation', label: 'Transportation' },
    { value: 'accommodation', label: 'Accommodation' },
    { value: 'entertainment', label: 'Entertainment' },
    { value: 'other', label: 'Other' },
  ];

  const handleSaveAndNew = () => {
    // Handle save and new logic
    setExpenseItemData({
      id: '',
      report: null,
      amount_paid: 0,
      amount_requested: 0,
      currency: 'CNY',
      txn_date: '',
      category: null,
      merchant_name: null,
      service_date: null,
      description: null,
      reference_number: null,
    });
  };

  const handleCancel = () => {
    onClose();
  };

  const draggerProps = {
    name: 'file',
    multiple: true,
    action: `${BASE_URL}/images/upload/`, // Replace with your API endpoint
    withCredentials: true, // This ensures that cookies are sent with the request
    data: {
      title: 'Receipt Upload', // Modify the title as required
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          width: '90%',
          margin: 'auto',
          marginTop: '5%',
          backgroundColor: 'white',
          padding: 4,
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: 'flex', marginTop: 4 }}>
          {/* Drag and Drop Upload Area */}
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 2,
            }}
          >
            <Dragger
              {...draggerProps}
              style={{
                width: '100%',
                padding: '20px',
                border: '1px dashed #d9d9d9',
                borderRadius: '4px',
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined style={{ fontSize: 50 }} />
              </p>
              <Typography variant="body1" sx={{ marginTop: 2 }}>
                Click or drag file to this area to upload
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ marginTop: 1 }}
              >
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files.
              </Typography>
            </Dragger>
          </Box>
          <Box sx={{ flex: 2, marginLeft: 4 }}>
            <form>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Expense Date"
                  value={
                    expenseItemData.txn_date
                      ? dayjs(expenseItemData.txn_date)
                      : null
                  }
                  name="txn_date"
                  onChange={(newValue) =>
                    handleInputChange({
                      target: { name: 'txn_date', value: newValue },
                    })
                  }
                  slotProps={{
                    textField: {
                      helperText: 'MM/DD/YYYY',
                    },
                  }}
                />
              </LocalizationProvider>
              <TextField
                label="Merchant"
                value={expenseItemData.merchant_name}
                name="merchant_name"
                onChange={handleInputChange}
                fullWidth
                sx={{ marginTop: 2 }}
              ></TextField>
              <FormControl fullWidth required sx={{ marginTop: 2 }}>
                <TextField
                  select
                  label="Category"
                  value={expenseItemData.category}
                  name="category"
                  onChange={handleInputChange}
                >
                  {/* todo: add icon to each category */}
                  {categories.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl required sx={{ marginTop: 2, width: '30%' }}>
                <TextField
                  id="outlined-select-currency"
                  select
                  label="Currency"
                  defaultValue="CNY"
                  helperText="Please select your currency"
                  name="currency"
                  value={expenseItemData.currency}
                  onChange={handleInputChange}
                >
                  {currencies.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <TextField
                label="Amount"
                name="amount_paid"
                value={expenseItemData.amount_paid}
                onChange={handleInputChange}
                fullWidth
                required
                type="number"
                sx={{ marginTop: 2 }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={claimReimbursement}
                    onChange={(e) => setClaimReimbursement(e.target.checked)}
                  />
                }
                label="Claim Reimbursement"
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Description"
                value={expenseItemData.description}
                name="description"
                onChange={handleInputChange}
                fullWidth
                multiline
                rows={4}
                sx={{ marginTop: 2 }}
              />
              <TextField
                label="Reference#"
                value={expenseItemData.reference_number}
                onChange={handleInputChange}
                name="reference_number"
                fullWidth
                sx={{ marginTop: 2 }}
              />
            </form>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 4 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAndClose}
            sx={{ marginRight: 2 }}
          >
            Save and Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSaveAndNew}
            sx={{ marginRight: 2 }}
          >
            Save and New
          </Button>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddExpenseModal;
