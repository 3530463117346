import React from 'react';
import PermanentDrawerLeft from '../Layout';

const MyProfilePage = () => {
  return (
    <PermanentDrawerLeft>
      <h1>My Profile</h1>
      <p>Welcome to your profile page!</p>
      {/* Add more profile-related content here */}
    </PermanentDrawerLeft>
  );
};

export default MyProfilePage;
