import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage';
import HomePage from './HomePage';
import MyExpensesPage from './expenses/MyExpensesPage.tsx';
import ReviewExpensesPage from './expenses/ReviewExpensesPage';
import MyProfilePage from './profile/MyProfilePage.tsx';
import ExpenseReportSubmission from './expenses/ExpenseReportSubmission.tsx';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/expenses/my" element={<MyExpensesPage />} />
        <Route path="/expenses/review" element={<ReviewExpensesPage />} />
        <Route path="/profile" element={<MyProfilePage />} />
        <Route path="/expenses/submit" element={<ExpenseReportSubmission />} />
      </Routes>
    </Router>
  );
};

export default App;
